import { replace as replaceRoute } from 'svelte-spa-router'
import logins from '$svr/providers/logins.json'
import { getDeviceInfo } from './device.js'
import { version } from '../../../../package.json'
import { locale } from 'svelte-i18n'
import { global } from '$src/state.svelte.js'
import { IS_PROD } from '$src/constants.js'
import { sessionCountdown, notification } from '$src/stores.js'

const checkVersion = (result) => {
    const versionServer = result && result.version
    const iso = new Date().toISOString()
    if (versionServer) {
        const color =
            version === versionServer ? 'color: green;' : 'color: red;'
        console.log(
            `%c Running Client v${version} | Server v${versionServer} (${iso})`,
            color,
        )
    }
}

const getDisplay = (slug) => {
    if (!slug) return ''
    const display = logins.find((i) => i.slug === slug)?.display
    if (display) return display
    else return slug.charAt(0).toUpperCase() + slug.slice(1)
}

const setAttributes = (node) => {
    node.setAttribute('nosociallinks', true)
    locale.subscribe((lang) => {
        if (lang) {
            node.setAttribute('lang', lang.split('-')[0])
        }
    })
}

const isValidDomain = function (domain) {
    // TODO: accept http://example.com, https://www.example.com but only send example.com to server
    return /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,63}$/.test(domain)
}

const makeLabel = (obj) => {
    const entries = Object.keys(obj).length
    if (!entries) return ''
    const labels = []
    const firstLabel = obj[Object.keys(obj)[0]]?.label
    if (firstLabel) {
        labels.push(firstLabel)
    }
    if (entries > 1) {
        const secondLabel = obj[Object.keys(obj)[1]]?.label
        if (secondLabel) {
            labels.push(secondLabel)
        }
    }
    if (labels.length) {
        return `(${labels.join(' & ')})`
    }
    return ''
}

const getRecommendedProviders = (
    providerHints = [],
    providerHintsMinus = [],
) => {
    let recommendedProviders = ['google', 'email'] //defaults
    let _recommendedProviders = Array.from(recommendedProviders)
    const { isApple, isWindows } = getDeviceInfo()
    if (isApple) {
        recommendedProviders = _recommendedProviders = [
            'apple',
            ...recommendedProviders,
        ]
    } else if (isWindows) {
        recommendedProviders = _recommendedProviders = [
            'microsoft',
            ...recommendedProviders,
        ]
    }
    //query param slugs
    if (providerHints?.length) {
        recommendedProviders = [...providerHints, ...recommendedProviders]
    }
    //query param slugs suffixed with --
    if (providerHintsMinus?.length) {
        recommendedProviders = recommendedProviders.filter(
            (i) => !providerHintsMinus.includes(i),
        ) //remove slugs suffixed with --
    }
    //defense for hiding all above fold provider via provider_hint query params
    if (!recommendedProviders?.length) {
        recommendedProviders = Array.from(_recommendedProviders)
        console.warn(
            'Reverting to showing default providers. You cannot demote all default providers.',
        )
    }
    recommendedProviders = [...new Set(recommendedProviders)] //remove duplicates
    return recommendedProviders
}

const dedupe = (arr, key) => {
    //not an object
    if (!key) {
        const deduped = [...new Set(arr)]
        if (deduped.length !== arr.length) {
            console.warn(`Found duplicates in ${JSON.stringify(arr, null, 2)}`)
        }
        return deduped
    }

    const deduped = []
    let hasDuplicates = false
    for (const item of arr) {
        const keys = deduped.map((i) => i[key])
        if (!keys.includes(item[key])) {
            deduped.push(item)
        } else {
            hasDuplicates = true
        }
    }
    if (hasDuplicates) {
        console.warn(
            `(Using key: "${key}") Found duplicates in ${JSON.stringify(arr, null, 2)}`,
        )
    }
    return deduped
}

const addSourcePropToPictureObj = (picturesArr) => {
    picturesArr.forEach((pictureObj) => {
        if (!pictureObj) return //defense for null or undefined
        if (!Object.prototype.hasOwnProperty.call(pictureObj, 'source')) {
            if (pictureObj.default) {
                pictureObj.source = 'default'
            } else if (pictureObj.uploaded) {
                pictureObj.source = 'upload'
            } else {
                pictureObj.source = pictureObj.display
            }
        }
    })
    return picturesArr
}

const OTP_LENGTH = 6
const validateOtp = (otp = '') => {
    // every letter in otp is a number and not empty string
    const allNums = [...otp].every(
        (element) => !isNaN(element) && element?.trim() !== '',
    )
    const hasReqLength = otp?.length === OTP_LENGTH
    return allNums && hasReqLength
}

const cleanUrl = () =>
    window.history.replaceState({}, document.title, window.location.pathname)

const preventDefault = (fn) => {
    return function (event) {
        event.preventDefault()
        fn.call(this, event)
    }
}

const stopPropagation = (fn) => {
    return function (event) {
        event.stopPropagation()
        fn.call(this, event)
    }
}

const timeout = (promise, timeoutMs) => {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject(
                new Error(
                    'Request timed out after waiting ' + timeoutMs / 1000 + 's',
                ),
            )
        }, timeoutMs)
        promise.then(
            (res) => {
                clearTimeout(timeoutId)
                resolve(res)
            },
            (err) => {
                clearTimeout(timeoutId)
                reject(err)
            },
        )
    })
}

const listenForInAppMessages = () => {
    const evtSource = new EventSource('/api/v1/login/in_app')
    evtSource.addEventListener('keep-alive', (event) => {
        if (!IS_PROD) {
            console.log('keep-alive: ' + event.data)
        }
    })
    evtSource.addEventListener('authorization_response', (event) => {
        const json = JSON.parse(event.data)
        if (!json) throw json
        global.data = {} //reset data
        window.location.href =
            window.location.pathname + '#' + new URLSearchParams(json)
        evtSource.close()
    })
}

const delayedListenForInAppMessages = () =>
    setTimeout(listenForInAppMessages, 1000)

function manageSessionCountdown(json) {
    // authorize session times out irrespsctive of login status
    if (window.isWalletAuthorizeApp()) return sessionCountdown.start()

    const checkLoginStatus = (data) => {
        return (
            ('isPersonalLoggedIn' in data && !!data.isPersonalLoggedIn) ||
            ('isManagedLoggedIn' in data && !!data.isManagedLoggedIn)
        )
    }
    // not all api call responses return logged in status for eg deleting a picture
    // in those case fall back to looking at global data since sessionCountdown is still active and needs to be updated
    let isLoggedIn
    const jsonKeysExist =
        'isPersonalLoggedIn' in json || 'isManagedLoggedIn' in json

    if (jsonKeysExist) isLoggedIn = checkLoginStatus(json)
    else isLoggedIn = checkLoginStatus(global.data)

    if (isLoggedIn) sessionCountdown.start()
    else sessionCountdown.clear()
}

function manageLoginStatus(json) {
    const msg = json?.error?.message
    if (
        ![
            'NO_SESSION',
            'SESSION_EXPIRED',
            'NO_SESSION_REQUEST',
            'USER_NOT_LOGGED_IN',
        ].includes(msg)
    )
        return false
    global.data = {}
    notification.show('Session expired. Please log in again.', 'error')
    replaceRoute('/login')
    return true
}

function cachePopHeader(res) {
    const pop = res.headers.get('x-amz-cf-pop')
    if (pop) global.helloPop = pop
}

function formatTime(seconds) {
    if (seconds < 60) return `${seconds} sec`

    const minutes = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${minutes}:${secs.toString().padStart(2, '0')} min`
}

function getHostName(uri) {
    if (!uri) return false
    try {
        const hostname = new URL(uri)?.hostname
        return hostname
    } catch (err) {
        console.error(err)
        return false
    }
}

export {
    checkVersion,
    getDisplay,
    setAttributes,
    isValidDomain,
    makeLabel,
    getRecommendedProviders,
    dedupe,
    addSourcePropToPictureObj,
    validateOtp,
    cleanUrl,
    preventDefault,
    stopPropagation,
    timeout,
    delayedListenForInAppMessages,
    manageSessionCountdown,
    manageLoginStatus,
    cachePopHeader,
    formatTime,
    getHostName,
}

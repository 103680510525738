import {
    timeout,
    manageSessionCountdown,
    manageLoginStatus,
    cachePopHeader,
} from '$utils/common.js'
import { global } from '$src/state.svelte.js'
import { API_BASE, CLIENT_REQUEST_TIMEOUT_MS } from '$src/constants.js'
import { version } from '../../../../package.json'

const send = async (method, path, query, params) => {
    let response, json, error

    const opts = {
        method,
        headers: {
            'x-hello-pop': global.helloPop,
            'x-hello-version': version,
        },
    }

    if (params instanceof FormData) {
        // binary (picture, etc)
        opts.body = params
    } else if (params) {
        // json
        opts.headers['Content-Type'] = 'application/json'
        opts.body = JSON.stringify(params)
    }

    if (query instanceof URLSearchParams) query = query.toString()

    const base = API_BASE + path
    const endpoint = query?.length ? base + '?' + query : base

    try {
        response = await timeout(
            fetch(endpoint, opts),
            CLIENT_REQUEST_TIMEOUT_MS,
        )
        cachePopHeader(response)

        // what is the response type? and only parse json if res type is json
        // check response header = content-length

        json = await response.json()
        if (response.ok) {
            manageLoginStatus(json) // TBD can take this out since session expired / not logged in errors, are now ~400
            manageSessionCountdown(json)
            return json
        }
    } catch (err) {
        // ReferenceError, TypeError, SyntaxError
        // NetworkError, JSONParseError
        error = err
    }

    if (!error) {
        if (response?.status >= 500) {
            error = new Error('~500 server in a confused state')
        } else if (response?.status >= 400) {
            if (json?.error && manageLoginStatus(json)) return json // session expired or not logged in -- dont throw and report to slack
            error = new Error('~400 client called server with bad data')
        }
    }

    const fetchContext = {
        status: response?.status,
        requestPath: method + ' ' + endpoint,
        requestBody: params || 'No JSON Request Body',
        responseBody: json || 'No JSON Response Body',
    }
    // this will be in error.reason.api
    error.fetchContext = fetchContext

    throw error
}

export { send }

import { writable, get } from 'svelte/store'
import { SESSION_EXPIRE_MS } from '$src/constants.js'
import { isLoading, locale } from 'svelte-i18n'

const _countdown = () => {
    const { subscribe, set, update } = writable(SESSION_EXPIRE_MS / 1000)
    let interval
    return {
        subscribe,
        _reset: function () {
            set(SESSION_EXPIRE_MS / 1000)
        },
        start: function () {
            this._reset()
            if (interval) clearInterval(interval)
            interval = setInterval(() => {
                update((n) => n - 1)
            }, 1000)
        },
        clear: function () {
            set(0)
            clearInterval(interval)
        },
    }
}

const _notification = () => {
    const { subscribe, set } = writable({})
    return {
        subscribe,
        show: (text, type, dismissable = true) => {
            // ensure locale file is loaded
            const localeExists = get(locale)
            const localeLoaded = !get(isLoading)
            if (localeExists && localeLoaded) {
                set({ text, type, dismissable })
            }
        },
        clear: () => set({}),
    }
}

export const sessionCountdown = _countdown()
export const verifyCountdown = _countdown()
export const notification = _notification()
